import { useCallback, useEffect, useState } from 'react';

type CarouselType = {
  currentIndex: number;
  goToNext: GenericHandler;
  goToPrevious: GenericHandler;
  goToIndex: (index: number) => void;
};

export type CarouselIntervalType = number | false;

export type CarouselConfig = {
  images: string[];
  interval?: CarouselIntervalType;
};

export function useCarousel(
  images: string[],
  interval: CarouselIntervalType = false
): CarouselType {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesLength = images.length;

  const calculateIndex = useCallback((value: number) => value % imagesLength, [imagesLength]);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => calculateIndex(prevIndex + 1));
  }, [setCurrentIndex, calculateIndex]);

  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => calculateIndex(prevIndex - 1 + imagesLength));
  }, [imagesLength, setCurrentIndex, calculateIndex]);

  const goToIndex = useCallback(
    (index: number) => {
      if (index >= 0 && index < imagesLength) {
        setCurrentIndex(index);
      }
    },
    [imagesLength, setCurrentIndex]
  );

  useEffect(() => {
    if (interval === false) return;

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => calculateIndex(prevIndex + 1));
    }, interval);

    return () => clearInterval(timer);
  }, [calculateIndex, setCurrentIndex, interval]);

  return { currentIndex, goToNext, goToPrevious, goToIndex };
}
