import { CarouselContent } from 'components/common/Carousel/CarouselContent';
import { CarouselProvider } from 'components/common/Carousel/CarouselProvider';
import { CarouselConfig } from 'hooks/useCarousel';
import { memo, ReactNode } from 'react';
import { SerializedComponentItem } from 'types/contentful';

export type CarouselProps = {
  items: SerializedComponentItem[];
  children?: ((currentImage: SerializedComponentItem) => ReactNode) | ReactNode;
} & ParentClassNameProp &
  Pick<CarouselConfig, 'interval'>;

export const Carousel = memo(({ items, children, className, interval }: CarouselProps) => {
  if (!items) return <></>;
  return (
    <CarouselProvider interval={interval} images={items?.map((item) => item.assets[0]?.url)}>
      <CarouselContent items={items} className={className}>
        {children}
      </CarouselContent>
    </CarouselProvider>
  );
});

Carousel.displayName = 'Carousel';
