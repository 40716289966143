'use client';
import { CarouselConfig, useCarousel } from 'hooks/useCarousel';
import { createContext, useContext } from 'react';

const CarouselContext = createContext(null);

export const CarouselProvider = ({
  children,
  images,
  interval = false
}: ChildrenProp & CarouselConfig) => {
  const carousel = useCarousel(images, interval);

  return <CarouselContext.Provider value={carousel}>{children}</CarouselContext.Provider>;
};

export const useCarouselContext = () => {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error('useCarouselContext must be used within a CarouselProvider');
  }
  return context;
};
